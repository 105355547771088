<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-box" v-if="formFlag">
        <el-form label-position="top" :model="euroSepaForm" ref="euroSepaForm" status-icon :rules="euroSepaRules">
          <div class="box_top">
            <div class="box_top_left box">
              <p>{{ $t('deposit.default.channels.eurosepa') }}</p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <strong>{{ $t('deposit.default.deposit') }}</strong>
              <div class="info">
                <p>
                  {{ $t('deposit.intSwift.desc', { platform: $config.info.fullName }) }}
                </p>
                <p>{{ $t('deposit.intSwift.options') }}</p>
              </div>
              <AccountNumber
                :supportedCurrenciesList="validCurrencies"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
              <!-- BANK DETAILS -->
              <div class="bank_info">
                <ul v-for="item in getBankInfo" :key="item.title">
                  <li>
                    <span class="title_name">{{ item.title }}</span>
                    <span class="value_info">{{ item.value }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="box_top_right">
              <div class="logo cc euroSepa"></div>
            </div>
          </div>
          <div class="box_bottom box">
            <div class="warn_info">
              <p>
                {{
                  $t('deposit.intSwift.refInfo', {
                    platform: $config.info.fullName
                  })
                }}
              </p>
            </div>
            <strong>{{ $t('deposit.default.sendReceipt') }}</strong>
            <div class="info">
              <p>{{ $t('deposit.intSwift.desc2') }}</p>
              <p>{{ $t('deposit.intSwift.bussDayInfo') }}</p>
            </div>
            <div class="form-list">
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.accNum')" class="special_input">
                    <div class="special_input_demo">{{ euroSepaForm.accountNumber }}</div>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item :label="setAmtLabel(accountCurrency, euroSepaForm.amount)" prop="amount">
                    <numeric-input
                      v-model="euroSepaForm.amount"
                      :currency="accountCurrency"
                      :precision="2"
                    ></numeric-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="euroSepaForm.notes" data-testid="notes"></el-input>
                    <div class="warn_info">
                      <p v-html="$t('deposit.intSwift.tip')"></p>
                    </div>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item class="button">
                    <el-button
                      class="btn-blue"
                      :loading="loading"
                      :disabled="loading"
                      @click="submitForm()"
                      data-testid="submit"
                    >
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </li>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiEuro_sepa } from '@/resource';

export default {
  name: 'euroSepa',
  components: { vUpload, NumericInput, AccountNumber, Result, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minimumLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minimumLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };

    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) {
        callback(new Error(this.$t('common.formValidation.fileReceipt')));
      }
      callback();
    };
    return {
      euroSepaForm: {
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: ''
      },
      validCurrencies: ['EUR'],
      euroSepaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      isShow: false,
      formFlag: true,
      successFlag: false,
      minimumLimit: 50,
      instData: [this.$t('deposit.reminder.inst14'), this.$t('deposit.reminder.inst15')],
      showNote: false,
      bankInfos: [
        {
          title: 'common.field.beneficiaryAccountName',
          value: 'PU Prime (PTY) LTD Client'
        },
        {
          title: 'common.field.bankIbanNum',
          value: 'GB69SPPV23188492214846'
        },
        {
          title: 'common.field.beneficiaryBankSwiftCode',
          value: 'SPPVGB2L'
        },
        {
          title: 'common.field.beneficiaryAccountNumber',
          value: '92214846'
        },
        {
          title: 'common.field.BeneficiaryBankSortCode',
          value: '23-18-84'
        },
        {
          title: 'common.field.beneficiaryBankName',
          value: 'Equals Money UK'
        },
        {
          title: 'common.field.bankAddress',
          value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom'
        },
        {
          title: 'common.field.paymentReference',
          value: 'common.field.paymentReferenceContext',
          parameter: {
            accountNumber: 'euroSepaForm.accountNumber'
          }
        }
      ]
    };
  },
  computed: {
    bankCurrency() {
      return this.$config.getIntBankTransferCurrency(this.regulator);
    },
    getBankInfo() {
      return this.bankInfos.map(el => {
        let paramsObj = {};
        if (el.obj) paramsObj = el.obj;
        if (el.parameter) {
          Object.entries(el.parameter).forEach(([key, value]) => {
            function getParameter(params, parentValue) {
              parentValue = parentValue[params[0]];
              params.splice(0, 1);
              if (params.length !== 0) parentValue = getParameter(params, parentValue);
              return parentValue;
            }
            paramsObj[key] = getParameter(value.split('.'), this);
          });
        }
        return {
          title: this.$t(el.title),
          value: this.$t(el.value, paramsObj)
        };
      });
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['euroSepaForm'].validateField('uploadFile');
    },
    setAccountNumber(accountNumber) {
      this.euroSepaForm.accountNumber = accountNumber;
      this.isShow = true;
    },
    submitForm() {
      this.$refs['euroSepaForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiEuro_sepa(
        {
          mt4Account: this.euroSepaForm.accountNumber,
          operateAmount: this.euroSepaForm.amount,
          applicationNotes: this.euroSepaForm.notes,
          currency: this.euroSepaForm.currency,
          fileList: this.fileList
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
